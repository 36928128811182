// @todo transferir para o winx-base

import { FetchError } from 'ofetch';
import { useBugsnag, useNotify, useRouter, useAuthUser } from '#imports';

export const useHandlingError = (error: any) => {
  let status = null;
  let message = '';
  const router = useRouter();
  const user = useAuthUser();

  if (error instanceof FetchError || error.value instanceof FetchError) {
    const { response, data }: FetchError = error instanceof FetchError ? error : error.value;

    const statusCode = response?.status;

    status = statusCode;
    message = data?.data?.detail;
  } else if (error instanceof Error) {
    // @ts-ignore
    const { statusCode, statusMessage } = error;

    status = statusCode;
    message = statusMessage;
  } else if (error instanceof Response) {
    const statusCode = error?._data.statusCode;
    const data = error?._data?.data;

    status = statusCode;
    message = data ? (statusCode === 422 ? data?.message : data?.detail) : error?._data.message;
  } else {
    useBugsnag().notify('[HandlingError:app] Error type not supported', (event) => {
      event.severity = 'danger';
      event.addMetadata('error', error);
      event.addMetadata('error.value', error.value);
    });

    return error;
  }

  switch (status) {
    case 400:
      sendNotifyError({ message, type: 'danger' });
      break;
    case 401:
      sendNotifyError({
        message,
        type: 'warning',
      });

      user.value = null;
      router.push('/login');

      break;
    case 403:
      sendNotifyError({
        message,
        type: 'danger',
      });
      break;
    case 404:
      sendNotifyError({
        message: 'Não foi possível encontrar o que você está procurando!',
        type: 'danger',
      });
      break;
    case 422:
      sendNotifyError({ message, type: 'danger' });
      break;
    case 500:
      sendNotifyError({
        message: 'Falha ao acessar o servidor! Verifique sua conexão e tente novamente',
        type: 'danger',
      });
      break;
    case 502:
      sendNotifyError({
        message: 'Falha ao acessar o servidor! Verifique sua conexão e tente novamente',
        type: 'danger',
      });
      break;
    case 503:
      sendNotifyError({
        message: 'Falha ao acessar o servidor! Verifique sua conexão e tente novamente',
        type: 'danger',
      });
      break;
    default:
      sendNotifyError({
        message:
          'Ops, não foi possível completar sua solicitação,' +
          ' atualize a página e tente novamente caso o erro persista entre em contato com nosso suporte!',
        type: 'danger',
      });
      break;
  }

  return error;
};

function sendNotifyError(msg: object | string) {
  if (process.client) {
    useNotify(msg);
  }
}
